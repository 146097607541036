import { useServices } from './services';

export function useRoadtrips() {
  const { roadtripsService } = useServices();

  return { 
    roadtrips: roadtripsService.roadtrips,
    deletedRoadtrips: roadtripsService.deletedRoadtrips
  }
};
